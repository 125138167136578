/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql, Link} from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import Header from "./header"
import "./css/layout.css"
import {StaticImage} from "gatsby-plugin-image"


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        <main id={'main'}>
          {children}
        </main>
        <div className="fluid red">
            <div className="container footer">
                <div className="row">
                    <div className="col-3 red">
                        <svg width="154" height="50" viewBox="0 0 154 50" fill="none" xmlns="http://www.w3.org/2000/svg" className={'logo-footer'}>
                            <g clipPath="url(#clip0)">
                                <path d="M3.7001 34.5H0.600098V2.7H20.3001V5.8H3.7001V34.5Z" fill="white"/>
                                <path d="M36.7001 2C41.5 2 45.5 3.6 48.8 6.8C52 10 53.7001 14 53.7001 18.6C53.7001 23.2 52.1001 27.2 48.9001 30.4C45.7001 33.6 41.7 35.3 37.1 35.3C32.4 35.3 28.4001 33.7 25.2001 30.5C22.0001 27.3 20.3 23.4 20.3 18.8C20.3 15.8 21 12.9 22.5 10.3C24 7.7 26 5.7 28.5 4.2C31 2.8 33.8001 2 36.7001 2ZM36.9001 5.1C34.6001 5.1 32.3001 5.7 30.2001 6.9C28.1001 8.1 26.5 9.8 25.3 11.8C24.1 13.9 23.5 16.2 23.5 18.7C23.5 22.5 24.8 25.7 27.4 28.3C30 30.9 33.2001 32.2 36.9001 32.2C39.4001 32.2 41.6 31.6 43.7001 30.4C45.8001 29.2 47.4 27.6 48.6 25.5C49.8 23.4 50.4001 21.1 50.4001 18.6C50.4001 16.1 49.8 13.8 48.6 11.8C47.4 9.8 45.8 8.1 43.6 6.9C41.5 5.7 39.3001 5.1 36.9001 5.1Z" fill="white"/>
                                <path d="M62 2.6H68.3C71.9 2.6 74.4 2.8 75.6 3.1C77.4 3.5 78.9 4.5 80 5.9C81.2 7.3 81.7 9 81.7 11.1C81.7 13.2 81.1 15 80 16.3C78.9 17.7 77.3 18.6 75.4 19.1C74 19.4 71.3 19.6 67.3 19.6H65V34.4H62V2.6ZM65.1 5.7V16.5L70.5 16.6C72.7 16.6 74.3 16.4 75.3 16C76.3 15.6 77.1 15 77.7 14.1C78.3 13.2 78.6 12.2 78.6 11.1C78.6 10 78.3 9.1 77.7 8.2C77.1 7.3 76.4 6.7 75.4 6.3C74.5 5.9 72.9 5.7 70.8 5.7H65.1Z" fill="white"/>
                                <path d="M90.2002 2.6H93.4002V14.5L106.1 2.6H110.4L95.1002 16.8L111.5 34.3H107.3L93.4002 19.5V34.3H90.2002V2.6Z" fill="white"/>
                                <path d="M132.2 0L112.4 34.4H152.1L132.2 0ZM132.2 26.6C130.4 26.6 129 25.2 129 23.4C129 21.6 130.4 20.2 132.2 20.2C134 20.2 135.4 21.6 135.4 23.4C135.4 25.1 134 26.6 132.2 26.6Z" fill="white"/>
                                <path d="M0.7 41.8V46L4.8 41.8H4.9V47.5H4.1V43.3L0.1 47.5H0V41.8H0.7Z" fill="white"/>
                                <path d="M20.5001 44.8H17.3001V47.5H16.6001V41.8H17.3001V44.1H20.5001V41.8H21.2001V47.5H20.5001V44.8Z" fill="white"/>
                                <path d="M35.8001 41.8H36.5001V44.3L39.2001 41.8H40.0001L37.4001 44.3L40.2001 47.5H39.3001L36.9001 44.7L36.5001 45.1V47.5H35.8001V45.1L35.4001 44.7L33.0001 47.5H32.1001L34.9001 44.3L32.3001 41.8H33.2001L35.8001 44.3V41.8Z" fill="white"/>
                                <path d="M51.7999 41.8V46L55.9 41.8H56V47.5H55.2999V43.3L51.2999 47.5H51.2V41.8H51.7999Z" fill="white"/>
                                <path d="M71.5001 44.8H68.3001V47.5H67.6001V41.8H68.3001V44.1H71.5001V41.8H72.2001V47.5H71.5001V44.8Z" fill="white"/>
                                <path d="M84.7 41.8V46L88.8 41.8H88.9V47.5H88.2V43.3L84.2 47.5H84V41.8H84.7Z" fill="white"/>
                                <path d="M100.5 41.8H101.2V42.8C101.5 42.4 101.8 42.1 102.2 41.9C102.6 41.7 103 41.6 103.4 41.6C104.2 41.6 104.9 41.9 105.4 42.5C106 43.1 106.2 43.8 106.2 44.6C106.2 45.4 105.9 46.1 105.4 46.7C104.8 47.3 104.2 47.6 103.4 47.6C103 47.6 102.6 47.5 102.2 47.3C101.8 47.1 101.5 46.8 101.2 46.5V49.6H100.5V41.8ZM103.4 42.4C102.8 42.4 102.3 42.6 101.8 43.1C101.4 43.5 101.2 44.1 101.2 44.8C101.2 45.2 101.3 45.6 101.5 46C101.7 46.3 102 46.6 102.3 46.8C102.7 47 103 47.1 103.4 47.1C103.8 47.1 104.1 47 104.5 46.8C104.8 46.6 105.1 46.3 105.3 45.9C105.5 45.5 105.6 45.1 105.6 44.7C105.6 44.3 105.5 43.9 105.3 43.5C105.1 43.1 104.8 42.9 104.5 42.7C104.1 42.5 103.8 42.4 103.4 42.4Z" fill="white"/>
                                <path d="M118.5 41.8V46L122.6 41.8H122.7V47.5H122V43.3L118 47.5H117.9V41.8H118.5Z" fill="white"/>
                                <path d="M138.2 44.8H135V47.5H134.3V41.8H135V44.1H138.2V41.8H138.9V47.5H138.2V44.8Z" fill="white"/>
                                <path d="M151.4 42.5V47.6H150.7V41.9H153.5V42.5H151.4Z" fill="white"/>
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="153.5" height="49.6" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <div className={'nav-footer'}>
                            <AnchorLink to={'/#activity'}>
                                Деятельность
                            </AnchorLink>
                            <AnchorLink to={'/#projects'}>
                                Проекты
                            </AnchorLink>
                            <Link to={'/sout'}>
                                СОУТ
                            </Link>
                            <Link to={'/contacts'}>
                                Контакты
                            </Link>
                        </div>
                    </div>
                    <div className="col-9">
                        <div className={'map-footer'}>
                            <StaticImage
                                src="../images/map-footer.jpg"
                                width={984}
                                quality={95}
                                formats={["AUTO", "WEBP"]}
                                alt="Партнеры"
                                layout={'constrained'}
                                objectPosition={'center 60%'}
                                className ='img-map-footer-div'
                                imgClassName = 'img-map-footer'
                            />
                        </div>
                        <div className={'footer-contacts'}>
                            <div className={'adress'}>
                                <p>ООО «ГОРКА Инжиниринг»</p>
                                <p>г. Москва, Кантемировская ул, дом 53, корпус 1, этаж 1, помещение 5, комната 2</p>
                            </div>
                            <div className={'href-footer'}>
                                <p><a className={'tel'} href='tel:84956629493'>+7 (495) 909 98 17</a></p>
                                <a href="mailto:info@gorka-engin.ru" className={'mail'}>info@gorka-engin.ru</a>
                                <a href="/doc/Gorka_Ing_booklet_screen.pdf" target="_blank" className="d-flex" rel="noopener">
                                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="file-pdf" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="svg-inline">
                                        <path fill="currentColor" d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z" className=""></path>
                                    </svg>
                                    <div className='pdf'>Комплексное сопровождение <br/>объектов строительства</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer
          style={{
            backgroundColor: `#b3b3b3`,
            height: `68px`
          }}
        >
        </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

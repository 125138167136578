import * as React from "react"
import { useState } from 'react';
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import {StaticImage} from "gatsby-plugin-image"

const Header = ({ siteTitle }) => {

    const [click, toggle] = useState('')


    return (

    <header>
        <div className="wrap">
            <Link to="/">
                <StaticImage
                    src="../images/logo.png"
                    width={300}
                    alt="Горка Инжиниринг"
                    className="logo"
                />
            </Link>
            <div className={'menu-mobil' + ' ' + click }>
                <div className={'close'} onClick={() => toggle('')} data-count={click}>
                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
                         className="svg-inline--fa fa-times fa-w-10 fa-3x">
                        <path fill="currentColor"
                              d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"
                              className=""></path>
                    </svg>
                </div>
                <nav>
                    <AnchorLink to={'/#activity'}><span onClick={() => toggle('')} data-count={click}>Деятельность</span></AnchorLink>
                    <AnchorLink to={'/#projects'}><span onClick={() => toggle('')} data-count={click}>Проекты</span></AnchorLink>
                    <Link to={'/all-projects'} onClick={() => toggle('')} data-count={click}>Все объекты</Link>
                    <Link to={'/sout'} onClick={() => toggle('')} data-count={click}>СОУТ</Link>
                    <Link to={'/contacts'} onClick={() => toggle('')} data-count={click}>Контакты</Link>
                </nav>
                <div className={'search-mobil'}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg">
                        <circle cx="7.45036" cy="7.45036" r="6.45036" fill="none" stroke="currentColor"
                                strokeWidth="2"/>
                        <rect x="12.165" y="11.802" width="7.01211" height="1.75303" rx="0.876513"
                              transform="rotate(45 12.165 11.802)" fill="currentColor"/>
                    </svg>
                </div>
            </div>
            <div className={'bars'} onClick={() => toggle('menu-vis')} data-count={click}>
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="bars" role="img"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                     className="svg-inline--fa fa-bars fa-w-14 fa-3x">
                    <path fill="currentColor"
                          d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z"
                          className=""></path>
                </svg>
            </div>
            <div className={'search mobil-none'}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="7.45036" cy="7.45036" r="6.45036" fill="none" stroke="currentColor" strokeWidth="2"/>
                    <rect x="12.165" y="11.802" width="7.01211" height="1.75303" rx="0.876513"
                          transform="rotate(45 12.165 11.802)" fill="currentColor"/>
                </svg>
            </div>
        </div>
    </header>
    )
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
